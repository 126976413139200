<template>
  <div class="page-wrapper">
    <MoleculePageHeader :title="article.title">
      <div class="col-auto ms-auto d-print-none">
        <div class="btn-list">
          <AtomButton
            :to="'/' + article.categoryKey"
            class="d-none d-sm-inline-block"
          >
            <arrow-left-icon />{{ $t('action.back') }}
          </AtomButton>
          <AtomButton
            v-if="isStaff"
            :to="'/article/edit/' + article.id"
            color="primary"
            class="d-none d-sm-inline-block"
          >
            <edit-icon />{{ $t('article.edit') }}
          </AtomButton>
          <AtomButton
            v-if="isStaff"
            :to="'/article/edit/' + article.id"
            color="primary"
            class="d-sm-none"
            :aria-label="$t('article.edit')"
          >
            <edit-icon />
          </AtomButton>
        </div>
      </div>
      <template
        v-if="article.publishedAt"
        slot="subtitle"
      >
        <calendar-icon class="me-1" />{{ article.publishedAt | date }}
      </template>
    </MoleculePageHeader>
    <div class="page-body">
      <div class="container-xl">
        <MoleculeCard
          size="lg"
          :image="article.image"
          image-position="bottom"
          :loading="loading"
          loading-type="placeholder"
        >
          <div
            v-if="article.content"
            v-html="article.content"
            class="markdown"
          />
        </MoleculeCard>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import MoleculePageHeader from '@/components/MoleculePageHeader'
import MoleculeCard from '@/components/MoleculeCard'
import AtomButton from '@/components/AtomButton'

export default {
  components: {
    MoleculePageHeader,
    MoleculeCard,
    AtomButton,
  },

  data: () => ({
    article: {
      id: '',
      title: '',
      introtext: '',
      content: '',
      image: '',
      createdAt: '',
      publishedAt: '',
    },
    loading: true,
  }),

  computed: mapGetters(['isStaff']),

  beforeRouteEnter (to, from, next) {
    next(async vm => {
      try {
        const article = await vm.$store.dispatch('getArticleDataById', to.params.id)
        vm.article = article
        vm.loading = false
        window.scrollTo({
          top: 0,
          behavior: 'instant',
        })
        next()
      } catch {
        next({ name: '404', replace: true })
      }
    })
  },
}
</script>
